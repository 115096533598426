import React from 'react'
import { useLocation } from 'react-router-dom'

function useGoToLocationHash(refs) {
  const location = useLocation()

  // Element that we want to scroll to must have an id attibute same as the #param
  React.useEffect(() => {
    if (!refs) return

    if (location.hash) {
      const elementId = location.hash.toLowerCase().slice(1)
      refs.forEach(ref => {
        if (ref?.current?.id === elementId) {
          ref.current.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          })
        }
      })
    }
  })
}
export default useGoToLocationHash
