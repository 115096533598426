import React from 'react'
import styled from 'styled-components'

import { device, theme } from '../../styles/theme'

const TagsArray = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 0.25em 0 0 0;
`

export const Tag = styled.span`
  display: block;
  color: white;
  border-radius: 4px;
  background-color: ${theme.grey};

  padding: 5px;
  margin: 0 0.5rem 0.5rem 0;
  min-width: 3rem;
  text-align: center;

  @media ${device.laptop} {
    margin: 0 0.5rem 0.5rem 0;
  }
`

export const Tags = ({ array, style }) => {
  return (
    <TagsArray style={style}>
      {array.map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
    </TagsArray>
  )
}
