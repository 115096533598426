import styled, { keyframes, css } from 'styled-components'
import { device, theme } from '../styles/theme'

export const Card = styled.section`
  position: relative;
  overflow: hidden;

  background: ${(props) =>
    props.background ? props.background : 'rgba(255, 255, 255, 0.8)'};
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : 'none'};

  margin: 0 0 2rem 0;

  width: calc(100%-2rem);
  min-width: ${(props) =>
    props.width ? `calc(${props.width} - 2rem)` : '30%'};

  @media ${device.tablet} {
    width: 45%;
    margin-bottom: 2rem;
  }
  @media ${device.laptop} {
    width: ${(props) => (props.width ? props.width : '30%')} !important;

    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem;
  }

  display: flex;
  flex-direction: column; /* mobile first */

  align-self: auto;
  /* justify-content: space-between; */
  text-align: left;

  color: ${(props) => (props.color ? props.color : theme.black)};

  &:hover .postContainer {
    bottom: 0;
  }
`

const fadeIn = keyframes`
  0% {
   opacity   : 0.1;
  }
  100% {
   opacity   : 0.9;
  }
`

const animCss = css`
  ${fadeIn} 0.5s ease-in-out forwards
`

export const AnimatedCard = styled(Card)`
  opacity: 0;
  transition: 0.3s linear;

  &.anim {
    animation: ${animCss};
  }

  &:hover {
    /* box-shadow: 1px 1px 1px 4px rgba(0, 0, 0, 0.3);     */
    transform: ${(props) => (props.onHover ? props.onHover : 'none')};
  }
`
