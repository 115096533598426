import React from 'react'
import { AnimatedCard } from '../Card'
import { Link } from 'react-router-dom'
import { Spinner } from '../loader'
import { Tags } from '../common/Tags'
import {
  PostTitle,
  StyledDate,
  PostDesciption,
  PostImage,
  PostContainer,
  ReadMoreButton,
} from './Elements'

function convertSecondsToTime(seconds) {
  return new Date(seconds * 1000).toDateString()
}

const Comments = ({ comments }) =>
  comments && comments.length ? <i>Comments: {comments.length}</i> : null

function delayAppearing(cardRef, index) {
  setTimeout(() => {
    if (cardRef && cardRef.current) {
      cardRef.current.className = `${cardRef.current.className} anim`
    }
  }, index * 250 /*ms*/)
}

export default function PostLink({ post, index }) {
  console.log('PostLink ', index, post)
  const { docId, title, description, created, tag } = post

  const cardRef = React.useRef()

  React.useEffect(() => {
    delayAppearing(cardRef, index)
  }, [index])

  return (
    <AnimatedCard ref={cardRef} onHover={'scale(1.04)'}>
      <PostImage post={post} />
      {!docId && <Spinner height='32vh'>Loading...</Spinner>}
      {docId && (
        <PostContainer>
          <PostTitle margin={'0 0 0 0'}>
            <Link to={`/posts/${docId}`}>{title}</Link>
          </PostTitle>
          <PostDesciption color='#ffffff'>{description}</PostDesciption>
          {created && created.seconds && (
            <StyledDate datetime={convertSecondsToTime(created.seconds)} />
          )}
          <Link to={`/posts/${docId}`}>
            <ReadMoreButton className='read-more-btn'>Read more</ReadMoreButton>
          </Link>
          <Comments comments={post.comments} />
        </PostContainer>
      )}
      {tag && (
        <Tags
          array={[tag]}
          style={{ position: 'absolute', top: '3px', right: '0px' }}
        />
      )}
    </AnimatedCard>
  )
}
