import React from 'react'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faHome } from '@fortawesome/free-solid-svg-icons'

import { device, theme } from '../../styles/theme'
import UserContext from '../../UserContext'
import useWindowScroll from '../../hooks/useWindowScroll'

const Nav = styled.nav`
  background-color: rgba(123, 123, 123, 0.7);
  background-image: linear-gradient(to right, ${theme.grey}, ${theme.darkGrey});
  width: 100%;
  margin: 0 0;
  z-index: 1000;

  &.invisible {
    display: none;
  }

  &.scrolled {
    display: inherit;

    background-image: linear-gradient(
      to right,
      ${theme.grey},
      ${theme.darkGrey}
    );
    font-size: 14px;
    position: fixed;
    top: 0;

    > ul li {
      padding-top: 0.25em;
      padding-bottom: 0.25em;
    }
  }
`

const Menu = styled.ul`
  display: flex;
  flex-direction: row;

  align-content: stretch;
  justify-content: start;

  list-style: none;

  @media ${device.laptop} {
    width: ${theme.laptopWidth};
    max-width: ${theme.maxWidth};
    margin: 0em auto;
  }
`

const MenuItem = styled.li`
  flex-grow: 1;
  @media ${device.laptop} {
    flex-grow: 0;
  }

  padding: 0.5em 1em;

  &.active {
    color: white;
    background-image: linear-gradient(
      to bottom left,
      grey,
      ${theme.highlightColor}
    );
    font-weight: bold;
  }

  &:hover {
    background-image: linear-gradient(
      to bottom left,
      grey,
      ${theme.highlightColor}
    );
    color: white;
  }

  & > svg {
    margin-right: 0.25rem;
  }
`
const NAV_SCROLLY_TRANSITION = 100

export function Navigation() {
  const history = useHistory()
  const location = useLocation()
  const user = React.useContext(UserContext)
  const windowScroll = useWindowScroll()
  const [isScrolled, setIsScrolled] = React.useState(false)

  const handleItemClick = name => {
    history.push(name)
  }

  function doesLocationMatchMenuName(name) {
    return (
      location.pathname &&
      ((location.pathname === '/' && name === '/home') ||
        location.pathname.startsWith(name))
    )
  }

  const menuItem = (text, name, icon) => (
    <MenuItem
      name={name}
      className={`${doesLocationMatchMenuName(name) ? 'active' : ''}`}
      onClick={_ => handleItemClick(name)}>
      {/* {icon && <FontAwesomeIcon icon={icon} />} */}
      {text}
    </MenuItem>
  )

  React.useLayoutEffect(() => {
    // console.log('useLayoutEffect', windowScroll.scrollY, isScrolled)
    if (windowScroll.scrollY > NAV_SCROLLY_TRANSITION && !isScrolled) {
      setIsScrolled(true)
    }
    if (windowScroll.scrollY < NAV_SCROLLY_TRANSITION && isScrolled) {
      setIsScrolled(false)
    }
  }, [windowScroll.scrollY, isScrolled])

  const NavigationElement = ({ className }) => (
    <Nav className={className}>
      <Menu>
        {menuItem('Home', '/home')}
        {menuItem('Posts', '/posts')}
        {menuItem('About me', '/about')}
        {menuItem('Contact', '/contact')}
        {user && !user.isAnonymous && menuItem('Admin', '/admin')}
      </Menu>
    </Nav>
  )

  return (
    <>
      <NavigationElement />
      <NavigationElement className={isScrolled ? 'scrolled' : 'invisible'} />
    </>
  )
}
