import React, { useState, useEffect } from 'react'

import PostService from '../services/PostService'
import Post from '../components/post/Post'
import { Spinner } from '../components/loader'
import { Page, Header2 } from '../styles/theme'
// import PostLink from '../components/post/PostLink'

export function PostsPage({ match }) {
  console.log('PostsPage()', match)

  const { postId } = match.params
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    async function fetchPostsFromDb() {
      console.log('fetchPostsFromDb')
      setIsLoading(true)
      setIsError(false)

      try {
        const newPosts = postId
          ? [await PostService.getPost(postId)]
          : await PostService.getAll()

        setPosts(newPosts)
      } catch (error) {
        console.error(error)
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
    fetchPostsFromDb()
  }, [postId])

  console.log('PostsPage render()', posts)

  return (
    <Page>
      {!postId && <Header2>All posts</Header2>}
      {isError && (
        <div style={{ color: 'red' }}>
          There was an error loading posts from db
        </div>
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='group'>
          {posts.map((post, index) => (
            <Post key={post.docId} post={post} index={index} />
          ))}
        </div>
      )}
    </Page>
  )
}
