import { Timestamp } from '../components/InitFirebase'

const dateFormat = new Intl.DateTimeFormat({
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
})

export const formatTimestamp2Date = (time, formater = dateFormat) => {
  console.log(time)
  if (!time) return 'no date'
  if (time instanceof Date) {
    return formater.format(time)
  }
  // Handle Google Firebase timestamp
  let seconds = time.seconds ?? 0
  let nanoseconds = time.nanoseconds ?? 0
  const timestamp = new Timestamp(seconds, nanoseconds)
  return formater.format(timestamp.toDate())
}
