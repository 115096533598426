import styled from 'styled-components'
import { device, theme } from '../../styles/theme'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const AdminForm = styled.form`
  max-width: ${theme.maxWidth};
  width: 100%;
  margin: 0em auto;
  color: black;

  display: grid;

  grid-template-columns: [left-col] 12fr [right-col] 0fr;
  grid-template-rows:
    [title] 2em
    [description] 1em
    [date] 1em
    [tag-name] 1em
    [image-name] 1em
    [picture] 4em
    [body] 40vh
    [submit-panel] 2em;
  grid-gap: 0.5em 1em /* grid-row-gap grid-column-gap */;

  @media ${device.laptop} {
    /* grid-row-gap grid-column-gap */
    grid-gap: 1em 2em;
  }
`

const boxShadowMixin = `
  border: 1px solid black;
  // border-radius: 5px;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.3);
`

const Label = styled.label`
  padding: 1px;
  margin: 0;
  text-align: right;
`

const Input = styled.input`
  padding: 5px;
  margin: 0;
  background-color: white;

  border: none;
  outline: 1px dotted grey;
  /* ${boxShadowMixin} */
`
export const InputTitle = styled(Input)``

export const LabelForTitle = styled(Label)``

export const LabelForDescription = styled(LabelForTitle)`
  grid-column: left-col / span 1;
  grid-row: description / span 1;
`
export const LabelForImageName = styled(LabelForTitle)`
  grid-column: left-col / span 1;
  grid-row: image-name / span 1;
`

export const InputPostBody = styled(ReactQuill)`
  grid-column: left-col / span 1;
  grid-row: body / span 1;
  padding: 0;
  margin: 0;
  resize: both;
  overflow: auto;
  z-index: 1;
  /* ${boxShadowMixin} */

  .ql-container.ql-snow {
    height: 80%;
  }
`

export const LabelForPostBody = styled(Label)`
  grid-column: left-col / span 1;
`

export const InputDescripton = styled(Input)`
  grid-column: left-col / span 1;
  grid-row: description / span 1;
`

export const InputDate = styled(Input)`
  grid-column: left-col / span 1;
  grid-row: date / span 1;
  max-width: 25%;
`

export const InputImageName = styled(Input)`
  grid-column: left-col / span 1;
  grid-row: image-name / span 1;
`

export const InputTagName = styled(Input)`
  grid-column: left-col / span 1;
  grid-row: tag-name / span 1;
`

export const PicturUploadRow = styled.div`
  grid-column: left-col / span 1;
  grid-row: picture / span 1;
  display: flex;
  flex-direction: row;
`
export const SubmitPanel = styled.div`
  grid-column: left-col / span 1;
  grid-row: submit-panel / span 1;
`

export const SubmitButton = styled(Input)`
  max-width: 10rem;
`

export const DeleteButton = styled.button`
  max-width: 6rem;
`

export const MarkdownHelpPanel = styled.div`
  grid-column: right-col / span 1;
  grid-row: title / span 1;
`

export const Preview = styled.div`
  margin-top: 1rem;
`
