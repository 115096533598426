import React from 'react'

const UserContext = React.createContext({
  user: {},
  dispatch: () => {},
})

export const Provider = UserContext.Provider
export const Consumer = UserContext.Consumer
export default UserContext
