import React from 'react'
import { Input } from './InputField.styled'

export const InputField = ({
  text,
  type = 'text',
  init,
  display = 'flex',
  component: HtmlInput = 'input',
  ...rest
}) => {
  const labelRef = React.useRef()
  const [value, setValue] = React.useState(init || '')

  const handleChange = e => {
    setValue(e.target.value)
  }

  const focused = e => {
    if (labelRef && labelRef.current) {
      labelRef.current.classList.add('moveUp')
    }
  }

  const blurred = e => {
    if (labelRef && labelRef.current && !value) {
      labelRef.current.classList.remove('moveUp')
    }
  }

  const id = text.toLowerCase().trim()

  return (
    <Input display={display}>
      <label ref={labelRef} htmlFor={id} className={value ? 'moveUp' : ''}>
        {text}
      </label>
      <HtmlInput
        required
        id={id}
        name={id}
        type={type}
        placeholder={text}
        onChange={handleChange}
        value={value}
        onFocus={focused}
        onBlur={blurred}
        {...rest}
      />
    </Input>
  )
}
