import React from 'react'

import UserContext from '../../UserContext'
import { ContactService } from '../../services/ContactService'
import { Spinner } from '../loader'
import {
  TwoColumns,
  MessageForm,
  Image,
  PrimaryButton,
  ActionPanel,
} from './Contact.styled'
import { Page, Header2, Header3 } from '../../styles/theme'
import { InputField } from './InputField'
import cloudsJpg from '../../assets/Just-clouds-1000x725px.jpg'

const clearForm = form => {
  form.querySelectorAll('input').forEach(input => {
    input.value = null
  })
}

export const ContactPage = () => {
  const user = React.useContext(UserContext)

  const [sent, setSent] = React.useState(false)
  const [contactSuccess, setContactSuccess] = React.useState(null)
  const [contactError, setContactError] = React.useState(null)
  const [invalidInputs, setInvalidInputs] = React.useState([])

  function sendContactMessage(inputs) {
    const message = {}
    inputs.forEach(input => {
      message[input.name] = input.value
    })
    if (!message.nothuman) {
      ContactService.addMessage(message)
        .then(function(docRef) {
          console.log('Document written with ID: ', docRef)
          setContactSuccess(docRef)
        })
        .catch(function(error) {
          console.error('Error adding document: ', error)
          setContactError(error)
        })
    }
  }

  function submitForm(e) {
    e.preventDefault()

    const inputs = []
    e.target.form.querySelectorAll('input').forEach(input => {
      inputs.push({
        name: input.name,
        value: input.value,
        isValid: input.checkValidity(),
      })
    })
    e.target.form.querySelectorAll('textarea').forEach(input => {
      inputs.push({
        name: input.name,
        value: input.value,
        isValid: input.checkValidity(),
      })
    })

    console.log('Form Values', inputs)
    const invalidInputs = inputs.filter(
      inp => !inp.isValid && inp.name !== 'nothuman',
    )
    if (invalidInputs && invalidInputs.length) {
      console.error('invalid inputs', invalidInputs)
      setInvalidInputs(invalidInputs)
    } else {
      sendContactMessage(inputs)
      setInvalidInputs([])
      clearForm(e.target.form)
      setSent(true)
    }
  }

  return (
    <Page>
      <Header2>Contact</Header2>
      <TwoColumns>
        {sent && !contactSuccess && !contactError && <Spinner />}
        {sent && contactSuccess && (
          <Header3>Thank you for your message!</Header3>
        )}
        {sent && contactError && (
          <div>
            <Header3>There was an error while sending your message :(</Header3>
            <span>{JSON.stringify(contactError.message || contactError)}</span>
          </div>
        )}
        {!sent && (
          <MessageForm key={user ? user.uid : ''}>
            <InputField text={'Name'} init={user ? user.displayName : null} />
            <InputField text={'NotHuman'} display='none' />
            <InputField
              text={'Email'}
              type='email'
              init={user ? user.email : null}
            />
            <InputField text={'Subject'} />
            <InputField
              text={'Message'}
              component='textarea'
              rows={5}
              cols={80}
            />

            <ActionPanel>
              <PrimaryButton onClick={submitForm}>Send</PrimaryButton>
              {invalidInputs.length > 0 && (
                <ul>
                  {invalidInputs.map(input => (
                    <li key={input.name}>Please fill: {input.name}</li>
                  ))}
                </ul>
              )}
            </ActionPanel>
          </MessageForm>
        )}
        <Image src={cloudsJpg} alt='Just clouds' />
      </TwoColumns>
    </Page>
  )
}
