import styled from 'styled-components'

/* https://www.hobo-web.co.uk/best-screen-size/
Most Common Desktop Screen Resolution Size in the United States Of America

1920×1080 – 19.81%
1366×768 – 16.02%
1440×900 – 9.38%
1536×864 – 6.58%
1600×900 – 4.57%
1280×800 – 4.49%

Most Common Mobile Screen Resolution Size in the United States Of America

375×667 – 15.75%
414×816 – 13.63%
360×640 – 11.62%
375×812 – 10.44%
414×736 – 10.11%
412×846 – 5.99%
320×568 – 3.6% (min-width)

Most common Tablet Screen Resolution Size in the United States Of America

768×1024 – 61.87%

*/
export const size = {
  mobile: '0px',
  tablet: '768px',
  laptop: '1200px',
  desktop: '1920px',
}

export const device = {
  mobile: `only screen and (min-width: ${size.mobile}) and (max-width: ${size.tablet})`,
  tablet: `only screen and (min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
}

export const theme = {
  laptopWidth: '80%',
  maxWidth: '1600px',
  highlightColor: '#e5474b',

  /* Navigation colors */
  grey: 'rgba(123, 123, 123, 0.8)',
  darkGrey: 'rgba(69, 69, 69, 0.8)',
  // secondary: '#5a5a5a',

  blue: 'rgba(92, 200, 255, 1)',
  green: 'rgba(147, 177, 167, 1)',
  yellow: 'rgba(222, 145, 81, 1)',
  red: 'rgba(243, 66, 19, 1)',
  black: 'rgba(9, 0, 8, 1)',
}

export const Page = styled.main`
  color: ${theme.black};
  border: none;

  display: flex;
  flex-direction: column;
  /* flex: flex-grow flex-shrink flex-basis */
  flex: 1 0 auto;

  width: 100%;
  max-width: ${theme.maxWidth};

  margin: 0.5rem 0;
  padding: 0.5rem 1rem;

  text-align: left;

  transition: 1s;

  > a {
    color: ${theme.highlightColor};
  }

  @media ${device.laptop} {
    margin: 1rem auto;
    padding: 0 0;
    width: 80%;
  }
`

export const Header2 = styled.h2`
  margin: 1em 1em 1em 0;
  font-size: 2em;
  color: ${theme.highlightColor};

  @media ${device.laptop} {
    margin-top: 2rem;
    margin-left: 0;
    text-align: left;
  }
`

export const Header3 = styled.h3`
  font-size: 1.5em;
  color: ${theme.highlightColor};

  margin: 0.5em 0.5em 0.5em 0;
`
