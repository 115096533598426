import styled from 'styled-components'
import { device, theme } from '../../styles/theme'

export const Image = styled.img`
  margin: 0rem;
  width: 100%;
  object-fit: cover;
  height: 32vh;

  border: solid 1px grey;
  float: left;
  shape-outside: ellipse();

  @media ${device.laptop} {
    max-width: 50%;
  }
`

export const TwoColumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.laptop} {
    flex-direction: row;
  }
`

export const PrimaryButton = styled.button`
  color: ${theme.highlightColor};
  background-color: #ffffff;
  height: 3rem;
  width: 10rem;
  font-size: 2rem;

  border-radius: 20px;
  border: 2px dashed ${theme.highlightColor};

  :hover,
  :focus {
    outline: none;
    color: #ffffff;
    background-color: ${theme.highlightColor};
    height: 3.2rem;
    width: 10.2rem;
  }

  :active {
    position: relative;

    top: 0.1rem;
    height: 2.9rem;
    width: 9.9rem;

    box-shadow: -2px 2px 10px grey inset, 2px -2px 10px grey inset;
  }

  :disabled {
    background-color: grey;
    color: white;
    border: 2px dashed grey;
  }
`

export const MessageForm = styled.form`
  width: calc(100%-2rem);
  margin: 1rem 1rem 1rem 0;

  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 40%;
    margin-left: 0;
  }
`

export const ActionPanel = styled.div`
  display: flex;
  flex-direction: row;

  & > ul {
    margin-left: 1rem;

    & > li {
      padding: 0.5rem;
      color: white;
      background-color: ${theme.highlightColor};
    }
  }
`
