const firebaseConfig = {
  apiKey: 'AIzaSyB50ITjHjpA76pakAOsW9V82kroLL2mE6g',
  authDomain: 'cloudtech-pl.firebaseapp.com',
  databaseURL: 'https://cloudtech-pl.firebaseio.com',
  projectId: 'cloudtech-pl',
  storageBucket: 'cloudtech-pl.appspot.com',
  messagingSenderId: '393823919272',
  appId: '1:393823919272:web:aae1761cc96f2e66',
}
export default firebaseConfig
