import React, { useState, useEffect } from 'react'
import PostPreview from './PostPreview'
import PostService from '../../services/PostService'
import { Link } from 'react-router-dom'
import PictureUpload from '../pictureUpload'
import { config } from './QuillConfig'
import { Quill } from 'react-quill'
import { formatTimestamp2Date } from '../../services/utils'
import {
  AdminForm,
  InputDescripton,
  InputTitle,
  InputPostBody,
  SubmitButton,
  InputImageName,
  PicturUploadRow,
  Preview,
  SubmitPanel,
  InputDate,
  InputTagName,
  DeleteButton,
} from './AdminPage.styled'
import { Page, Header2, Header3 } from '../../styles/theme'
import UserContext from '../../UserContext'

export function AdminPage({ match }) {
  console.log('AdminPage')
  const { postId } = match.params
  const user = React.useContext(UserContext)
  const quillRef = React.useRef(null)

  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [description, setDescription] = useState('')
  const [imageName, setImageName] = useState('')
  const [createdDocument, setCreatedDocument] = useState(null)
  const [showImage, setShowImage] = useState(true)
  const [submitConsole, setSubmitConsole] = useState('')
  const [createdDate, setCreatedDate] = useState(new Date())
  const [tag, setTag] = useState('')
  const post = {
    author: user,
    title,
    description,
    body,
    imageName,
    created: createdDate,
    tag,
  }

  useEffect(() => {
    async function fetchPostAsync() {
      try {
        const post = await PostService.getPost(postId)
        setTitle(post.title)
        setBody(post.body)
        setDescription(post.description)
        setImageName(post.imageName)
        setCreatedDate(post.created)
      } catch (error) {
        console.error(error)
      }
    }
    if (postId) {
      fetchPostAsync()
    }
  }, [postId])

  const handleChange = (content, delta, source, editor) => {
    // console.log('Quill', delta, source, editor)
    setBody(content)
  }

  const updatePost = (post) => {
    if (window.confirm('Update post?')) {
      console.log('Update post', post)
      PostService.updatePost(post).then((docRef) => {
        const status = `Document updated: ${docRef.path}`
        setSubmitConsole(status)
        clearInputs(docRef)
      })
    }
  }

  const createNewPost = (post) => {
    if (window.confirm('Do you want to publish this post?')) {
      console.log('Create new post')
      PostService.addPost(post)
        .then((docRef) => {
          const status = `Document written with ID: ${docRef.path}`
          setSubmitConsole(status)
          clearInputs(docRef)
        })
        .catch((error) => {
          console.error('Error adding document: ', error)
          setSubmitConsole(`Error adding document: ${error}`)
        })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (postId) updatePost({ ...post, postId })
    else createNewPost(post)
  }

  const deletePost = (event) => {
    event.preventDefault()

    if (postId && window.confirm('DELETE post?')) {
      const postToDelete = postId
      PostService.deletePost(postToDelete)
        .then(() => {
          setSubmitConsole('Deleted post ', postToDelete)
          clearInputs(null)
        })
        .catch((error) => {
          console.error('Error deleting post', error)
          setSubmitConsole(`Error deleting post ${error}`)
        })
    }
  }

  const showAdminForm = () => {
    console.log('ShowAdminForm')
    const pictureUploadDirectory = title
      ?.toLowerCase()
      .trim()
      .slice(0, 64)
      .split(' ')
      .join('_')

    const dateFormat = {
      format: (date) => date?.toISOString().slice(0, 10),
    }

    return (
      <>
        <AdminForm onSubmit={handleSubmit}>
          <InputTitle
            id='title'
            name='title'
            type='text'
            value={title}
            placeholder='Title'
            onChange={(e) => setTitle(e.target.value)}
          />
          <InputDescripton
            id='description'
            name='description'
            type='text'
            value={description}
            placeholder='Description'
            onChange={(e) => setDescription(e.target.value)}
          />
          <InputDate
            id='date'
            name='date'
            type='date'
            value={formatTimestamp2Date(createdDate, dateFormat)}
            placeholder='Created date'
            onChange={(e) => {
              const date = new Date(e.target.value)
              setCreatedDate(date)
            }}
          />
          <InputTagName
            id='tag'
            name='tag'
            type='text'
            value={tag}
            placeholder='Tag'
            onChange={(e) => setTag(e.target.value)}
          />
          <InputImageName
            name='imageName'
            type='text'
            value={imageName}
            placeholder='Image Name'
            onChange={(e) => setImageName(e.target.value)}
          />
          <InputPostBody
            id='post'
            name='post'
            placeholder='Start writing your post!'
            theme='snow'
            value={body}
            onChange={handleChange}
            modules={config.modules}
            formats={config.formats}
            ref={quillRef}
          />

          <PicturUploadRow>
            {pictureUploadDirectory && (
              <PictureUpload
                directoryName={pictureUploadDirectory}
                handleClick={insertPicture}
              />
            )}
          </PicturUploadRow>
          <SubmitPanel>
            <SubmitButton type='submit' value='Submit' />
            <DeleteButton onClick={deletePost}>Delete</DeleteButton>
            <span id='log'>{submitConsole}</span>
          </SubmitPanel>
        </AdminForm>

        {createdDocument && (
          <Link to={createdDocument.path}>
            New document was created: {createdDocument.id}
          </Link>
        )}

        <Preview>
          <label>
            <input
              type='checkbox'
              name='showImage'
              checked={showImage}
              onChange={() => setShowImage(!showImage)}
            />
            <span>Show image preview</span>
          </label>

          <PostPreview post={post} showImage={showImage} />
        </Preview>
      </>
    )
  }

  const insertPicture = (imageUrl) => {
    console.log('insertPicture', imageUrl, quillRef)
    if (!imageUrl || !quillRef) return

    const quillInstance = quillRef.current.getEditor()
    if (quillInstance) {
      const range = quillInstance.getSelection()
      const position = range ? range.index : 0
      quillInstance.insertEmbed(position, 'image', imageUrl, Quill.sources.USER)
    }
  }

  return (
    <Page>
      <Header2>Admin</Header2>
      {user && !user.isAnonymous ? (
        showAdminForm()
      ) : (
        <Header3>Forbidden</Header3>
      )}
    </Page>
  )

  function clearInputs(docRef) {
    setCreatedDocument(docRef)
    setTitle('')
    setDescription('')
    setBody('')
  }
}
