import React from 'react'
import styled from 'styled-components'
import { device } from '../../styles/theme'

import EY from '../../assets/logos/ey.png'
import Benq from '../../assets/logos/benq.png'
import DXC from '../../assets/logos/dxc.png'
import CreditSuisse from '../../assets/logos/credit-suisse.png'
import TitianSoftware from '../../assets/logos/titian-software.png'
import Cranfield from '../../assets/logos/cranfieldUni.png'
import iiUwr from '../../assets/logos/uwr.png'
import OnlineTech from '../../assets/logos/onlineTech.png'

const logos = {
  EY: EY,
  'BenQ Poland': Benq,
  'DXC Technology': DXC,
  'Credit Suisse': CreditSuisse,
  'Titian Software Ltd': TitianSoftware,
  'University of Wroclaw': iiUwr,
  'Cranfield University, UK': Cranfield,
  'Online Technology (Poland)': OnlineTech,
}

const Picture = styled.picture`
  position: absolute;
  left: -3.7em;
  top: 1.8em;
  img {
    opacity: 0.8;
    max-width: 3rem;
    max-height: 3rem;
  }
  @media ${device.laptop} {
    left: -6rem;
    top: 2rem;

    img {
      max-width: 4rem;
      max-height: 4rem;
    }
  }
`

export const CompanyLogo = ({ name }) => {
  if (name in logos) {
    return (
      <Picture>
        <img src={logos[name]} alt={`Company ${name} logo`} />
      </Picture>
    )
  } else console.error('Missing company logo', name)
  return null
}
