import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Input = styled.div`
  display: ${props => props.display};
  flex-direction: column;

  input,
  textarea,
  select {
    margin-bottom: 1rem;

    border: none;
    border-bottom: 2px dashed ${theme.black};
    line-height: 2rem;

    color: ${theme.black};
    background-color: transparent;

    ::placeholder {
      color: transparent;
    }

    &:valid {
      background-color: #5cc8ff23;
      /* background: url(images/check.svg);
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: 20px 20px; */
    }
    &:invalid {
      /* light-red */
      background-color: #e5474b23;
    }
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    border-bottom: 2px dashed ${theme.highlightColor};
  }

  label {
    position: relative;
    top: 1.8rem;
    transition: all 0.5s ease;

    &.moveUp {
      top: 0rem;
      font-size: 0.9rem;
    }
  }
`
