import React from 'react'
import Post from '../post/Post'

export default function PostPreview({ post, showImage }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1rem auto',
        maxWidth: '1600px',
      }}>
      <Post index={0} post={post} showHtml={false} showImage={showImage} />
    </div>
  )
}
