import React from 'react'

import { Page, Header2, Header3 } from '../../styles/theme'
import Timeline from './Timeline'
import cvData from './cv'
import AboutMe from './AboutMe'
import useGoToLocationHash from '../../hooks/useGoToLocationHash'
// import { CloudLoader } from '../loader/Cloud'

const AboutPage = () => {
  const resumeRef = React.useRef(null)

  useGoToLocationHash([resumeRef])

  return (
    <Page>
      <Header2>About me</Header2>
      {/* <CloudLoader></CloudLoader> */}
      <AboutMe />

      {/* <Header3>Hobbies</Header3>
      <Columns>
        <p>Travel</p>
        <p>Sports</p>
        <p>Bachata</p>
      </Columns> */}

      <Header3 id='resume' ref={resumeRef}>
        Resume
      </Header3>
      <Timeline cvData={cvData}></Timeline>
    </Page>
  )
}
export default AboutPage
