import React from 'react'
import withFirebaseAuth from 'react-with-firebase-auth'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import { Navigation } from './components/nav'
import { HomePage } from './pages/HomePage'
import { PostsPage } from './pages/PostsPage'
import { AdminPage } from './components/admin'
import { providers, firebaseAppAuth } from './components/InitFirebase'
import { PageHeader } from './components/header'
import { UserLogin } from './components/UserLogin'
import { ContactPage } from './components/contact'
import UserContext from './UserContext'
import AboutPage from './components/aboutPage'
import { PageFooter } from './components/footer'

function App({ user, signOut, signInWithGoogle }) {
  return (
    <BrowserRouter>
      <PageHeader />

      <UserContext.Provider value={user}>
        <UserLogin signOut={signOut} signInWithGoogle={signInWithGoogle} />
        <Navigation />
        <Switch>
          <Route path='/home' component={HomePage} />
          <Route path='/posts/:postId' component={PostsPage} />
          <Route exact path='/posts' component={PostsPage} />
          <Route exact path='/admin/:postId' component={AdminPage} />
          <Route exact path='/admin' component={AdminPage} />
          <Route exact path='/contact' component={ContactPage} />
          <Route exact path='/about' component={AboutPage} />
          {/* Default: */}
          <Route exact path='/' component={HomePage} />
        </Switch>
      </UserContext.Provider>
      <PageFooter />
    </BrowserRouter>
  )
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App)
