import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { theme } from '../../styles/theme'
import { CloudLoader } from './Cloud'
// loader inspired by https://projects.lukehaas.me/css-loaders/
const growAndShrink = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
`

const animCss = css`
  ${growAndShrink} 1s infinite ease-in-out
`

const lineMixin = css`
  background: ${theme.highlightColor};
  animation: ${animCss};
  width: 1em;
  height: 4em;
  position: absolute;
  top: 0;
  content: '';
`

const Container = styled.div`
  position: absolute;
  left: 45%;
  top: 20%;

  color: ${theme.highlightColor};
  text-indent: -9999em;
  margin: 1em auto;
  padding: 0 1em;
  font-size: 11px;
  transform: translateZ(0);
  height: ${(props) => (props.height ? props.height : 'inherit')};
`

const JumpingRects = styled.div`
  color: ${theme.highlightColor};
  text-indent: -9999em;
  margin: 4em auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s !important;
  ${lineMixin}

  &:before {
    ${lineMixin};
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &:after {
    ${lineMixin}
    left: 1.5em;
    animation-delay: 0s !important;
  }
`

export function Spinner({ height }) {
  return (
    <Container height={height}>
      <JumpingRects>Loading...</JumpingRects>
    </Container>
    // <CloudLoader />
  )
}
