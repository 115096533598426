import { useState, useEffect } from 'react'

const initial = { scrollX: 0, scrollY: 0 }

function useWindowScroll() {
  let [scroll, setScroll] = useState(initial)
  // console.log('useWindowScroll ', scroll)

  useEffect(
    () => {
      const handleScroll = () => {
        setScroll({
          scrollX: window.scrollX,
          scrollY: window.scrollY,
        })
      }

      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    },
    [] /* RUNS ONCE */,
  )

  return scroll
}

export default useWindowScroll
