import React from 'react'
import styled from 'styled-components'
import { device } from '../../styles/theme'
import { StyledDate } from './Elements'
const Comment = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: row;

    & > h3 {
      margin-right: 1rem;
    }

    & > p {
      margin-left: 1rem;
    }
  }
`

export function Comments({ comments }) {
  return comments && comments.length ? (
    <div>
      Comments: {comments.length}
      {comments.map(comment => (
        <Comment key={comment}>
          <h3>{comment.author}</h3>
          <StyledDate>{comment.datetime}</StyledDate>
          <p>{comment.comment}</p>
        </Comment>
      ))}
    </div>
  ) : null
}
