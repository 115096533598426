import React from 'react'
import { Link } from 'react-router-dom'
import UserContext from '../../UserContext'
import { formatTimestamp2Date } from '../../services/utils'
import { Tags } from '../common/Tags'
import {
  PostTitle,
  PostDesciption,
  PostInfo,
  StyledDate,
  PostBody,
  PostImage,
} from './Elements'
import { AnimatedCard } from '../Card'
import { Comments } from './Comments'

const ANIMATION_DELAY = 50 /*ms*/

const AdminEditButton = ({ post }) => {
  const user = React.useContext(UserContext)

  return user && !user.isAnonymous && user.uid === post.authorId ? (
    <Link to={'/admin/' + post.docId}>edit post</Link>
  ) : null
}

export default function Post({
  post,
  index = 0,
  showHtml = false,
  showImage = true,
}) {
  console.log(`${index} Post `, post)

  const cardRef = React.useRef()
  React.useEffect(() => {
    setTimeout(() => {
      if (cardRef && cardRef.current) {
        cardRef.current.className = `${cardRef.current.className} anim`
      }
    }, index * ANIMATION_DELAY)
  }, [index])

  return (
    <AnimatedCard ref={cardRef} width={'100%'}>
      {showImage && <PostImage post={post} />}
      <PostTitle>
        <Link to={'/posts/' + post.docId}>{post.title}</Link>
        {post.tag && (
          <Tags
            array={[post.tag]}
            style={{
              display: 'inline-block',
              fontSize: '0.75em',
              margin: '0 1em 0 3em',
            }}
          />
        )}
      </PostTitle>

      <PostDesciption>{post.description}</PostDesciption>
      <PostInfo>
        {post.created && (
          <StyledDate datetime={formatTimestamp2Date(post.created)} />
        )}
        {post.updated && (
          <StyledDate
            datetime={`&nbsp;updated:&nbsp;${formatTimestamp2Date(
              post.updated,
            )}`}
          />
        )}

        <AdminEditButton post={post} />
      </PostInfo>
      {showHtml ? (
        <div>{post.body}</div>
      ) : (
        <PostBody dangerouslySetInnerHTML={{ __html: post.body }}></PostBody>
      )}
      <Comments comments={post.comments} />
    </AnimatedCard>
  )
}
