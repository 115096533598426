import React from 'react'
import styled from 'styled-components'
import { theme } from '../../styles/theme'

const Footer = styled.footer`
  flex-shrink: 0;

  width: 100%;
  background-color: ${theme.black};
  color: #ffffff55;
  text-align: center;
  padding: 0.5rem 0;
`

export const PageFooter = () => {
  return <Footer>Cloudtech (c) 2019</Footer>
}
