import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { device, theme } from '../../styles/theme'
// import { Logo } from './Logo'
import CloudtechLogo from '../../assets/Cloudtech-Logo-red.svg'

const Header = styled.header`
  color: ${theme.highlightColor};
  background-color: rgba(000, 000, 000, 0.9);

  width: 100%;
  height: 5rem;

  opacity: 0.99;
  overflow-x: hidden;
  overflow-y: hidden;

  padding: 0.5em 0;
  flex: /*flex-grow*/ 0 /*flex-shirnk*/ 0 auto;

  @media ${device.laptop} {
    padding: 1em 0;
  }

  > h1 {
    position: absolute;
    left: -9999px;
    top: 0px;
    height: 1rem;
  }

  .container {
    margin: 0 auto;
    width: 100%;

    @media ${device.laptop} {
      width: ${theme.laptopWidth};
      max-width: ${theme.maxWidth};
    }
  }

  img {
    /* color: white; */
    padding: 0em 1em;
    width: 20rem; /*400px;*/
    @media ${device.laptop} {
      width: 25rem;
      max-width: ${theme.maxWidth};
      padding: 0 0;
    }
  }
`

export function PageHeader() {
  return (
    <Header>
      <div className='container'>
        <Link to={`/`}>
          <img src={CloudtechLogo} alt='Cloudtech Adrian Ciura Logo' />
        </Link>
        {/* <Logo></Logo> */}
      </div>
      <h1>Cloudtech Adrian Ciura</h1>
    </Header>
  )
}
