import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import placeholderImage from '../../assets/Just-clouds-1000x725px.jpg'
import { storage } from '../../components/InitFirebase'

import { theme, device } from '../../styles/theme'
import { Spinner } from '../loader'

export const PostContainer = styled.div`
  position: absolute;
  padding: 1em;
  background: rgba(33, 32, 31, 0.65);
  bottom: -2.5em;
  width: 100%;
  transition: 0.3s linear;
  display: flex;
  flex-direction: column;

  &:hover {
    bottom: 0;
    box-shadow: 1em 1em 1em 1em ${theme.black};
  }

  &:hover .read-more-btn {
    /* ReadMoreButton */
    opacity: 1;
  }
`

const Image = styled.img`
  margin: 0rem;
  width: 100%;
  object-fit: cover;

  border: solid 1px grey;
  float: left;
  shape-outside: ellipse();

  @media ${device.tablet} {
    height: 32vh;
  }
`
const imagesDirPath = '/images'
const imagesDirRef = storage.ref(imagesDirPath)

export const PostImage = ({ post }) => {
  const [imageUrl, setImageUrl] = React.useState(null)
  const [isLoaded, setIsLoaded] = React.useState(false)

  const handleImageLoaded = (e) => {
    console.log('img loaded', e.nativeEvent)
    setIsLoaded(true)
  }

  const handleImageError = (e) => {
    console.error('img error', e.nativeEvent)
    setIsLoaded(false)
  }

  React.useEffect(() => {
    if (post.imageName) {
      const imageRef = imagesDirRef.child(post.imageName)
      console.info('Image Ref fullPath', imageRef.fullPath)

      imageRef
        .getDownloadURL()
        .then((url) => {
          console.log('Image download url', url)
          setImageUrl(url)
        })
        .catch((err) => {
          console.error('Image download url error', err)
          setImageUrl(placeholderImage)
        })
    } else setImageUrl(placeholderImage)
  }, [post])

  return (
    <>
      {!isLoaded && <Spinner height='32vh'>Loading...</Spinner>}
      <Image
        style={{ display: isLoaded ? 'block' : 'none' }}
        src={imageUrl}
        onLoad={handleImageLoaded}
        onError={handleImageError}
      />
    </>
  )
}

export const PostTitle = styled.h1`
  font: 700 20px /1.2 'Overpass Mono', monospace;
  color: ${theme.highlightColor};
  margin: ${(props) => props.margin ?? '0.5em 0 0 0'};
  > a {
    color: ${theme.highlightColor};
  }
  @media ${device.tablet} {
    margin: ${(props) => props.margin ?? '1em 0 0 0'};
  }
`

export const PostDesciption = styled.h2`
  font: 400 16px /1.2 'Roboto', sans-serif;
  margin: 0.5rem 0 0 0;

  color: ${(props) => props.color ?? '#111111'};

  @media ${device.tablet} {
    margin-left: 0;
  }
`

export const ReadMoreButton = styled.div`
  display: inline-block;

  color: ${theme.highlightColor};
  border: 1px solid ${theme.highlightColor};
  padding: 6px 20px;
  margin-top: 0.5em;
  opacity: 0;
  transition: 0.3s linear;

  :hover {
    background: ${theme.highlightColor};
    color: #fff;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export const PostInfo = styled.div`
  font: 20px /1.2 'Roboto', sans-serif;
  margin: 0 0 0;

  @media ${device.tablet} {
    margin-left: 0;
  }
`

export const PostBody = styled.div`
  color: ${theme.black};
  text-align: left;
  margin: 1rem 0 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${theme.black};

  @media ${device.tablet} {
    margin-left: 0;
  }

  strong {
    color: inherit;
    font-weight: bold;
  }

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5 {
    margin-bottom: 0.5rem;
  }

  > p {
    margin-bottom: 0.5rem;
  }

  > ul {
    margin-bottom: 1rem;

    > li {
      list-style: square inside;
      display: list-item;
      padding-left: 4px;
    }
  }

  > ol {
    list-style: decimal inside;
    margin-bottom: 1rem;
    > li {
      display: list-item;
    }
  }
`

const StyledDateSpan = styled.span`
  font: 16px /1.2 sans-serif;
  color: lightgrey;
  margin-top: 0.5em;
  margin-right: 0.5em;
`

export const StyledDate = ({ datetime }) => (
  <StyledDateSpan>
    <FontAwesomeIcon icon={faClock} />
    &nbsp;
    {datetime}
  </StyledDateSpan>
)
