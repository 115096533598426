import React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import PostService from '../services/PostService'
import PostLink from '../components/post/PostLink'
import { AboutMe } from '../components/aboutPage'

import { device, Page } from '../styles/theme'

const Posts = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-content: stretch;

  min-height: 25vh;

  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export function HomePage() {
  console.log('HomePage function')
  const [posts, setPosts] = useState([{}, {}, {}])

  useEffect(() => {
    console.log('PostService.getLatest(3)')
    PostService.getLatest(3).then((newPosts) => {
      setPosts(newPosts)
    })
  }, [])

  console.log('HomePage render()', posts)
  return (
    <Page>
      <Posts>
        {posts.map((post, i) => (
          <PostLink key={i} post={post} index={i} />
        ))}
      </Posts>

      <Link to='/posts'>
        <h2>See all posts {'>'}</h2>
      </Link>

      <AboutMe />

      <Link to='/about#resume'>
        <h2>Check out my resume {'>'}</h2>
      </Link>
    </Page>
  )
}
