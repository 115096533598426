import { db } from '../components/InitFirebase'

function convertDocToPost(doc) {
  return { docId: doc.id, ...doc.data() }
}

const PostService = {
  getAll: async () => {
    const querySnapshot = await db
      .collection('posts')
      .orderBy('created', 'desc')
      .get()
    return querySnapshot.docs.map(convertDocToPost)
  },

  getLatest: async (numberOf) => {
    const query = await db
      .collection('posts')
      .orderBy('created', 'desc')
      .limit(numberOf)
      .get()
    return query.docs.map(convertDocToPost)
  },

  getPost: async (postId) => {
    console.log('getPost', postId)
    const docRef = db.collection('posts').doc(postId)
    const doc = await docRef.get()

    if (doc.exists) {
      console.log(doc)
      const post = convertDocToPost(doc)
      console.log('returning', post)
      return post
    } else {
      const err = `Document ${postId} does not exist`
      console.error(err)
      return { docId: postId }
    }
  },

  addPost: async ({
    title,
    description,
    body,
    author,
    imageName,
    created = new Date(),
    tag,
  }) => {
    const docRef = await db.collection('posts').add({
      author: author.displayName,
      authorId: author.uid,
      title,
      description,
      body,
      imageName,
      created,
      tag,
    })
    return docRef
  },

  updatePost: async ({
    postId,
    title,
    description,
    body,
    author,
    imageName,
    created,
    tag,
  }) => {
    console.log('updatePost', postId)
    const docRef = db.collection('posts').doc(postId)
    docRef
      .update({
        author: author.displayName,
        authorId: author.uid,
        title,
        description,
        body,
        imageName,
        created,
        tag,
      })
      .then((result) => {
        console.log('post updated', result)
      })
    return docRef
  },

  deletePost: async (postId) => {
    console.log('deletePost', postId)
    const docRef = db.collection('posts').doc(postId)
    return docRef.delete().then((result) => {
      console.log('post deleted', result)
    })
  },
}
export default PostService
