// Firebase
import firebaseConfig from '../firebaseConfig'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()

const storage = firebase.storage()

const firebaseAppAuth = firebaseApp.auth()
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
}
const Timestamp = firebase.firestore.Timestamp

export { firebaseAppAuth, providers, storage, db, Timestamp }
