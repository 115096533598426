import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { Tags } from '../common/Tags'
import { CompanyLogo } from './CompanyLogo'
import { device, theme } from '../../styles/theme'

const TimelineContainer = styled.div`
  position: relative;
  background-color: transparent;
  margin-left: 50px;
  padding: 1em 0;

  @media ${device.laptop} {
    margin-left: 100px;
    padding: 1em 0;
  }

  /* red vertical line */
  &:after {
    content: '';
    width: 4px;
    height: 100%;
    background-color: ${theme.highlightColor};
    position: absolute;
    left: -4px;
    top: 0;
    border-radius: 10px;
  }
`

export const Header4 = styled.h4`
  font-size: 1.2em;
  color: ${theme.black};
  /* margin-top: 0rem !important; */
  margin: 0.5em 0.5em 0 0;
`

export const Header5 = styled.h4`
  font-size: 1em;
  color: ${theme.black};
  /* margin-top: 0rem !important; */
  margin: 0.5em 0.5em 0 0;
`

function getYearMonth(date) {
  const dateObj = new Date(date)
  const year = dateObj.getUTCFullYear()
  const month = dateObj.toLocaleString('default', { month: 'short' })

  return (
    <>
      {year}&nbsp;{month}
    </>
  )
}

const TimeDisplayMixin = `
position: absolute;
opacity: 0.5;
font-size: 0.8em;
@media ${device.laptop} {
  font-size: 1rem;
}
`

const startMarkerTop = '7em'
const startMarkerLeft = '-6rem'

const TimeEnd = styled.div`
  ${TimeDisplayMixin};
  display: none;
  left: ${startMarkerLeft};
  top: 8.5em;
  @media ${device.laptop} {
    left: ${startMarkerLeft};
    top: 8.5em;
  }
`

const TimeStart = styled.div`
  ${TimeDisplayMixin};

  left: -5em;
  top: ${startMarkerTop};
  @media ${device.laptop} {
    left: ${startMarkerLeft};
    top: ${startMarkerTop};
  }
`

const Content = styled.div`
  margin: 0;
  padding-bottom: 0;

  @media ${device.laptop} {
    /* margin: 1rem; */
    padding-bottom: 1rem;
  }
`

const Button = styled.button`
  display: block;

  color: ${theme.black};
  background-color: #ffffff;
  font-size: 1rem;

  border-radius: 10px;
  border: 1px dashed ${theme.black};
  outline: none;

  padding: 5px;
  margin: 0.5em 0 0.5em 0;

  @media ${device.laptop} {
    margin: 1rem 0 1rem 0;
  }

  :disabled {
    background-color: grey;
    color: white;
    border: 2px dashed grey;
  }
`

const showAnim = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

const apearAnim = css`
  ${showAnim} 0.5s forwards ease-in-out
`

const Description = styled.div`
  display: none;
  max-width: 100%;

  transition-duration: 0.5s;
  transition-delay: 0.1s;
  transition-property: opacity;

  font-size: 0.8em;

  @media ${device.laptop} {
    font-size: 1em;
  }

  &.open {
    display: block;
    animation: ${apearAnim};
  }
`

const Duration = styled.div`
  display: none;
  position: absolute;
  opacity: 0.5;
  font-size: 1rem;
  left: -4rem;
  bottom: 6rem;
  transform: rotate(-90deg);
`

const TimelineItem = styled.div`
  position: relative;
  padding-top: 1em;
  padding-left: 0.5em;
  padding-bottom: 1em;

  @media ${device.laptop} {
    padding: 1rem;
  }
  /* TimelineItem dot, the top one */
  &:after {
    display: none;

    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 20px solid ${theme.highlightColor};
    border-bottom: 10px solid transparent;
    background-color: transparent;
    position: absolute;
    left: -20px;
    top: -1em;
    z-index: 9;
  }

  /* TimelineItem dot, bottom one */
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid ${theme.highlightColor};
    /* border-top: 10px solid transparent;
  border-right: 20px solid ${theme.highlightColor};
  border-bottom: 10px solid transparent; */

    background-color: white;
    position: absolute;
    left: -12px;
    top: ${startMarkerTop};
    z-index: 9;
  }
`

const MS_PER_DAY = 1000 * 60 * 60 * 24
function dateDiffInDays(a, b) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / MS_PER_DAY)
}

const NUMBER_OF_DAYS_IN_YEAR = 365

const Job = ({ job }) => {
  const durationInDays = dateDiffInDays(new Date(job.start), new Date(job.end))
  const durationInYears = Math.floor(durationInDays / NUMBER_OF_DAYS_IN_YEAR)
  const durationInMonth = Math.ceil(
    (durationInDays % NUMBER_OF_DAYS_IN_YEAR) / 30,
  )
  const descriptionRef = React.useRef(null)
  const detailsClick = () => {
    if (descriptionRef.current.classList.contains('open')) {
      descriptionRef.current.classList.remove('open')
    } else {
      descriptionRef.current.classList.add('open')
    }
  }
  return (
    <TimelineItem>
      <TimeStart>{getYearMonth(job.start)}</TimeStart>
      <TimeEnd>{getYearMonth(job.end)}</TimeEnd>
      <Content>
        <Header4>{job.title}</Header4>
        <Header5>
          <span>{job.type}</span>&nbsp;<span>{job.company}</span>
        </Header5>

        <Tags array={job.techStack}></Tags>
        <Button onClick={detailsClick}>Details</Button>
        <Description
          ref={descriptionRef}
          dangerouslySetInnerHTML={{ __html: job.description }}></Description>

        <CompanyLogo name={job.company}></CompanyLogo>
        <Duration>
          {durationInYears}y {durationInMonth}mon
        </Duration>
      </Content>
    </TimelineItem>
  )
}

const Timeline = ({ cvData }) => {
  return (
    <TimelineContainer>
      {cvData.jobs.map((data) => (
        <Job key={data.company + data.start} job={data} />
      ))}
    </TimelineContainer>
  )
}
export default Timeline
