import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'

import { device, theme } from '../styles/theme'
import UserContext from '../UserContext'

const LoginElement = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: grey;

  & > p {
    display: none;
    @media ${device.tablet} {
      display: block;
    }
  }

  & > button {
    border: none;
    color: lightgrey;
    background: transparent;
    width: 2rem;
    height: 2rem;
    font-size: 20px;
    border-radius: 1rem;

    &:hover {
      background: linear-gradient(
        to bottom left,
        black,
        ${theme.highlightColor}
      );
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.95);
    }
  }
`

export function UserLogin({ signOut, signInWithGoogle }) {
  const user = React.useContext(UserContext)
  console.log('User:', user)

  return (
    <LoginElement>
      {/* {user ? <p>{user.displayName}</p> : <p>Annonymous.</p>} */}
      {user && user.photoURL && (
        <img
          style={{ width: '3em', height: '3em' }}
          src={user.photoURL}
          alt='Logged in user'
        />
      )}
      {user ? (
        <button onClick={signOut}>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </button>
      ) : (
        <button onClick={signInWithGoogle}>
          <FontAwesomeIcon icon={faUserCircle} />
        </button>
      )}
    </LoginElement>
  )
}
