import React from 'react'
import styled from 'styled-components'

import { device, theme } from '../../styles/theme'
import { Tags } from '../common/Tags'
import myPhoto from '../../assets/me.jpg'
import linkedInLogo from '../../assets/logos/linkedin-brands.svg'
import githubLogo from '../../assets/logos/github-square-brands.svg'
import twitterLogo from '../../assets/logos/twitter-square-brands.svg'

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;

  background: transparent;
  color: ${theme.black};

  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-shrink: 1;

  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const ImageNoBorder = styled.img`
  margin: 0rem;
  width: 100%;
  max-width: 300px;
  object-fit: cover;

  float: left;
  shape-outside: ellipse();

  /* transition: 1s ease-in-out;
  filter: grayscale(100%) blur(5px);

  :hover {
    filter: none;
  } */
`

const AboutContainer = styled.div`
  margin-left: 0em;
  margin-top: 1em;

  text-align: left;

  @media ${device.tablet} {
    margin-left: 1em;
    margin-top: 2em;
  }
`

export const Header3 = styled.h3`
  font-size: 1.5em;
  color: ${theme.highlightColor};

  margin: 0.5em 0.5em 0.5em 0;
`

const SocialContainer = styled.div`
  background: transparent;
  color: ${theme.black};

  @media ${device.tablet} {
    border-left: 4px solid ${theme.highlightColor};
    padding-left: 1em;
    /* margin-bottom: 1rem; */
  }

  ul {
    list-style: none;

    display: flex;

    align-content: flex-start;
    justify-content: flex-start;

    @media ${device.tablet} {
      flex-direction: column;
    }

    @media ${device.laptop} {
      flex-direction: row;
    }

    li {
      display: flex;
      flex-direction: column-reverse;
      /* width: 2rem; */

      /* height: 2em; */

      margin-right: 1em;

      color: ${theme.black};

      img {
        height: 4em;
      }
    }
  }
`

const SocialLinks = () => {
  return (
    <SocialContainer>
      <Header3>Social</Header3>
      <ul>
        <li>
          Github
          <a href='https://github.com/aciura'>
            <img src={githubLogo} alt='My profile on Github' />
          </a>
        </li>
        <li>
          LinkedIn
          <a href='https://www.linkedin.com/in/aciura/'>
            <img src={linkedInLogo} alt='My profile on LinkedIn' />
          </a>
        </li>
        <li>
          Twitter
          <a href='https://twitter.com/AdrianCiura'>
            <img src={twitterLogo} alt='My profile on Twitter' />
          </a>
        </li>
      </ul>
    </SocialContainer>
  )
}

const specializeTags = [
  'JavaScript',
  'React',
  'TypeScript',
  'nodeJs',
  'Google Firebase',
  'Ms SQL Server',
  'Angular',
  'C#',
]

const AboutMe = () => {
  return (
    <>
      <Columns>
        <ImageNoBorder src={myPhoto} alt='Site admin' />
        <AboutContainer>
          <p>
            My name is Adrian Ciura. I am a passionate Software Engineer with
            more than 10 years of experience.
          </p>
          <p>
            I am running a software consulting company Cloudtech, working on a
            wide range of web projects.
          </p>
          I specialize in following technologies:
          <br />
          <Tags array={specializeTags} />
        </AboutContainer>
        <SocialLinks />
      </Columns>
    </>
  )
}
export default AboutMe
