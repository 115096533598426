export default {
  jobs: [
    {
      title: 'Senior Web Developer',
      company: 'EY',
      start: '2018.10.01',
      end: new Date().toUTCString(),
      type: 'contract for',
      location: 'Wroclaw',
      description: `<p>
        Developing web projects for Global Innovations department.
        Using latest ReactJs & Redux web stack deployed on Azure Cloud.
      </p>`,
      techStack: ['JavaScript', 'React', 'Redux', 'Azure'],
    },
    {
      title: 'Full-Stack Senior Software Developer',
      company: 'DXC Technology',
      start: '2017.06.01',
      end: '2018.09.30',
      type: 'contract for',
      location: 'Wroclaw',
      description: `<p>Developing a Web based portal using TypeScript, JavaScript, Angular5, nodeJS, Ms Sql Server.</p>`,
      techStack: ['TypeScript', 'Angular5', 'nodeJs', 'MS SQL Server'],
    },
    {
      title: 'Team Lead, AVP',
      company: 'Credit Suisse',
      start: '2014.07.01',
      end: '2017.05.30',
      type: 'employed at',
      location: 'Wroclaw',
      description: `<p>
        Technical Team Lead and Scrum Master role in Structured Products / Equity Derivatives Pricing & Risk Systems.
      </p>`,
      techStack: ['AngularJs', 'C#', '.NET', 'MS SQL Server'],
    },
    {
      title: 'Senior Software Developer (contracted)',
      company: 'Credit Suisse',
      start: '2013.04.01',
      end: '2014.06.30',
      type: 'contract for',
      location: 'Wroclaw',
      description: `<p>
        FX Sales Analytics team - Working in agile team on development of Real Time, scalable, customer analytics/data mining platform.
        <br/> Design and implementation of new functionalities. Unit & Integration testing.
      </p>`,
      techStack: [
        'C#',
        'Silverlight',
        'MS SQL Server',
        'Reactive-Extensions (Rx)',
      ],
    },
    {
      title: 'Software Engineer',
      company: 'Titian Software Ltd',
      start: '2009.06.01',
      end: '2013.03.30',
      type: 'contract for',
      location: 'Wroclaw',
      description: `<p>
        Working as a contractor for Titian Software (www.titian.co.uk), a company providing platform for pharmaceutical sample management.
        I have been responsible for design and development of new functionalities for many modules across the main Mosaic system.
      </p>

      <p> Job responsibilities: <br/>
      Design, Coding, Writing unit tests, functional tests and documentation.
      Integration tests on client site in the USA.
      </p>`,
      techStack: ['C#', '.NET', 'Oracle DB', 'VB6'],
    },
    {
      title: 'Software Developer, Associate',
      company: 'Credit Suisse',
      start: '2008.04.01',
      end: '2009.01.30',
      type: 'employed at',
      location: 'Wroclaw',
      description: `<p>
        Software Developer within Credit Suisse - Global Modelling & Analytics Group (GMAG) part of Investment Banking.
      </p>

      <p> Job responsibilities: <br />
        Design, Coding and Testing of web and desktop software applications.
      </p>`,
      techStack: ['C#', 'ASP.NET', 'Oracle Database'],
    },
    {
      title: 'Software Developer',
      company: 'Online Technology (Poland)',
      start: '2007.09.01',
      end: '2008.03.30',
      type: 'employed at',
      location: 'Wroclaw',
      description: `<p>
        Took part in the development of VICOS-LIO Automatic Vehicle Location and Control system for Siemens VDO Automotive AG \\ Continental.
      </p>
      <p>
        Job responsibilities: <br/>
        Design, coding, testing and documenting of software applications.
      </p>`,
      techStack: ['C# 2.0', 'C++', 'VB6', 'MS SQL Server'],
    },
    {
      title: 'Master of Science',
      company: 'Cranfield University, UK',
      start: '2006.10.01',
      end: '2007.10.30',
      type: 'studied at',
      location: 'Cranfield, UK',
      description: `<p>Degree Name: Master of Science (MSc),
      <br/>
      Field Of Study: Grid Computing and e-Engineering
      <br/>
      Master thesis: "Resource Location and Discovery in Large-Scale Grid Environments"
      </p>`,
      techStack: ['Python', 'C++'],
    },
    {
      title: 'Software Developer (Summer Internship)',
      company: 'BenQ Poland',
      start: '2006.06.01',
      end: '2006.09.30',
      type: 'employed at',
      location: 'Wroclaw',
      description: `<p>
      Design and development of a distributed tool for work planning according to SCRUM lightweight agile method.
      Project consisted of client applications connecting to multi-threaded server using .NET Remoting.
      The server stored data in PostgreSQL database.
      </p>

      <p> Job responsibilities: <br/>
        Design of multi-threaded server, software development and testing.
      </p>`,
      techStack: ['Test Driven Development', 'C#', '.NET', 'PostgreSQL'],
    },
    {
      title: 'Computer Science',
      company: 'University of Wroclaw',
      start: '2001.10.01',
      end: '2006.06.30',
      type: 'studied at',
      location: 'Wrocław, Poland',
      description: `<p>
        Degree Name: Bachelor degree (BSc)
      </p>`,
      techStack: ['C#', 'C++'],
    },
  ],
}
