import React from 'react'
import styled from 'styled-components'
import { storage } from '../InitFirebase'

const UploadedImageStyle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 8px;
`

const ImagePreview = styled.img`
  width: 64px;
  height: 64px;
`

const UploadedImage = ({ upload, onClick }) => {
  return (
    <ImagePreview
      src={upload.downloadURL}
      alt='Uploaded preview'
      onClick={() => {
        onClick(upload.downloadURL)
      }}
    />
  )
}

const FileUploadLabel = styled.label`
  border: 1px solid black;
  padding: 0.5em;
  cursor: pointer;
`

const FileUploadInput = ({ handleUpload, labelText }) => {
  return (
    <>
      <FileUploadLabel htmlFor='upload-picture'>{labelText}</FileUploadLabel>
      <input
        id='upload-picture'
        type='file'
        onChange={handleUpload}
        style={{
          opacity: '0',
          position: 'absolute',
          zIndex: '-1',
        }}
      />
    </>
  )
}

const PictureUpload = ({ directoryName, handleClick }) => {
  const [uploaded, setUploaded] = React.useState([])

  const handleUpload = (e) => {
    const storageRef = storage.ref()
    const file = e.target.files[0]
    const picRef = storageRef.child(`uploads/${directoryName}/${file.name}`)
    console.log('picRef', picRef)
    picRef
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL)
          setUploaded([...uploaded, { downloadURL }])
        })
      })
      .catch((error) => console.error(error))
  }
  return (
    <>
      <FileUploadInput
        labelText='Choose file to upload...'
        handleUpload={handleUpload}
      />
      <UploadedImageStyle>
        {uploaded.map((upload) => (
          <UploadedImage
            key={upload.downloadURL}
            upload={upload}
            onClick={handleClick}
          />
        ))}
      </UploadedImageStyle>
    </>
  )
}
export default PictureUpload
