import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { theme } from '../../styles/theme'

const move = keyframes`
  from{
    left:200px;
  }
  to{
    left:0px;
  }
`

const fade = keyframes`
  0%{ opacity: 0;}
  10%{ opacity: 1;}
  90%{ opacity:1;}
  100%{ opacity:0;}
`

const fadeAnim = css`
  ${fade} 1s infinite ease-in-out
`

const moveAnim = css`
  ${move} 3s infinite ease-in-out
`

const Cloud = styled.div`
  /* animation-duration: 10s; */
  animation: ${moveAnim}, ${fadeAnim};
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;

  position: relative;
  display: inline-block;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iY2xvdWQiIHZpZXdCb3g9IjAgMCA1MiA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI1MnB4IiBoZWlnaHQ9IjQwcHgiPgoJPGRlZnM+CgkJPGZpbHRlciBpZD0iZjEiIHg9Ii0xMDAlIiB5PSItMTAwJSIgd2lkdGg9IjMwMCUiIGhlaWdodD0iMzAwJSI+IAoJCQk8ZmVPZmZzZXQgcmVzdWx0PSJvdXQiIGluPSJTb3VyY2VHcmFwaGljIiBkeD0iMCIgZHk9IjEiLz4KCQkJPGZlQ29sb3JNYXRyaXggcmVzdWx0PSJvdXQiIGluPSJvdXQiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgIDAgMCAwIDAgMCAgMCAwIDAgMCAwICAwIDAgMCAwLjQgMCIvPgoJCQk8ZmVHYXVzc2lhbkJsdXIgcmVzdWx0PSJvdXQiIGluPSJvdXQiIHN0ZERldmlhdGlvbj0iMiIvPgoJCQk8ZmVCbGVuZCBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJvdXQiIG1vZGU9Im5vcm1hbCIgcmVzdWx0PSJkcCIvPgoJCTwvZmlsdGVyPgoJPC9kZWZzPiAKCTxwYXRoIGlkPSJmZy1jbG91ZCIgZmlsdGVyPSJ1cmwoI2YxKSIgZD0iTTYuMyAzNS4xQzQuNyAzNC4yLTAuNCAzMi4zIDEuNCAyNSAzLjEgMTguMSA4LjcgMTkuNSA4LjcgMTkuNSA4LjcgMTkuNSAzLjIgMTQuMSAxMC40IDYuOCAxNi45IDAuMiAyMy4xIDQuNiAyMy4xIDQuNiAyMy4xIDQuNiAzMC0xLjcgMzUuMiAyLjQgNDQuNiA5LjcgNDIuOCAyNS4zIDQyLjggMjUuMyA0Mi44IDI1LjMgNDggMjIuNiA0OS44IDI4LjYgNTEgMzIuNyA0NiAzNS44IDQyLjggMzYuNyAzOS43IDM3LjUgOC45IDM2LjYgNi4zIDM1LjFaIiBzdHJva2U9IiNjY2NjY2MiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0iI2ZmZmZmZiIvPgo8L3N2Zz4=);
  height: 40px;
  width: 53px;
  margin: 0 auto;
`

const CloudSmall = styled(Cloud)`
  animation-duration: 3s;
  /* top: 65px; */
  transform: scaleX(0.5) scaleY(0.5);
`
const CloudMedium = styled(Cloud)`
  animation-duration: 2.5s;
  animation-delay: 1s;
  /* top: 95px; */
  transform: scaleX(0.7) scaleY(0.7);
`

const CloudLarge = styled(Cloud)`
  animation-duration: 2s;
  animation-delay: 2.5s;
  /* top: 95px; */
  transform: scaleX(0.8) scaleY(0.8);
`

export const CloudLoader = () => (
  <div style={{ position: 'relative', background: 'blue' }}>
    <CloudLarge />
    <CloudMedium />
    <CloudSmall />
  </div>
)
